import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import httpCommon, {getHttpCommon} from '../../httpCommon'
import UserContext from './../Context/SampleContext'
import { loginRequest } from "../../authConfig";
import {validateRole } from '../../Constants/Utilities';
import { format } from "date-fns";

export default class AddUser extends React.Component {
    static contextType = UserContext;

    constructor(props,context) {
        super(props,context)

        this.userId = props.userId;
        this.storeId = props.storeId;

        this.loginUserPermission = context.userPermission
        this.loginUserEmail = context.userEmail

        this.loginUserRoles = []
        if(context.accountObj[0]!=undefined){
          this.loginUserRoles =  context.accountObj[0].idTokenClaims?.roles;
        }
        this.mslInstance = context.msalObj;

        this.state = {
            name: '',
            validated: false,
            roles: [],
            stores: [],
            accessToken: '',
            passCodeValidTo: '',
            roleValidFrom: '',
            roleValidTo: '',
            isDisclaimerChecked: false,
            isDisclaimerOn: false,
            isADValidationSuccess: false
        }
    }
    

    componentDidMount() {
    
        this.mslInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.loginUserRoles
        }).then((response) => {
            console.log(response.accessToken);
            this.setState({ accessToken: response.accessToken })
            this.loadRolesList(response.accessToken);
            this.loadStoreList(response.accessToken);
        });

        var currDate = new Date();
        currDate.setDate(currDate.getDate() + 90);
        var formattedDate = format(currDate, "yyyy-MM-dd HH:mm");
        this.setState({passCodeValidTo: formattedDate})
        this.setState({roleValidFrom: format((new Date()), "yyyy-MM-dd HH:mm")})
        this.setState({roleValidTo: formattedDate})
    }

    //To load Store list 
    loadStoreList(accessToken){
        //TODO: ***** Validate the ROLE and build the Route *****

        //[HM_SADMIN Role]: Load All Store items as it is super admin
        //var routePath = 'api/store' //If the login user is Superadmin
        
        //[HM_ADMIN Role]: Load Store List by login user email 
        //var routePath = 'api/Store/storeByUserEmail/' + this.loginUserEmail;

        var routePath = 'api/Store/'
        var isSuperAdmin = validateRole(this.loginUserRoles, ["HM_SADMIN"]);
        if (isSuperAdmin) {
            //[HM_SADMIN Role]: Load All Store items as it is super admin
            routePath = 'api/Store/';
        }
        else {
            //[HM_ADMIN Role]: Load Store List by login user email 
            routePath = 'api/Store/storeByUserEmail/' + this.loginUserEmail;
        }

        getHttpCommon(accessToken).get(routePath)
        .then(res => {

            this.setState({
                stores: res.data
            });

        }).catch(function (error) {

            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);

        });
    }

    //To Load roles/job description details in the dropdown
    loadRolesList(accessToken){
        getHttpCommon(accessToken).get('api/Role')
        .then(res => {

            this.setState({
                roles: res.data
            });

        }).catch(function (error) {

            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);

        });
    }

    handleUserIdChange = event => {
        this.setState({ userId: event.target.value });
    }

    handleFirstNameChange = event => {
        this.setState({ firstName: event.target.value });
    }

    handleLastNameChange = event => {
        this.setState({ lastName: event.target.value });
    }

    handleEmailChange = event => {
        var email = event.target.value;
        // if (validator.isEmail(email)) {
        //     this.setState({ emailerror: ''});
        //   } else {
        //     this.setState({ emailerror:'please enter valid email'});

        //   }
        this.setState({
            email: email
        });
        //alert(this.state.email)
    }

    handlePassCodeChange = event => {
        this.setState({ passCode: event.target.value });
    }

    handleStoreChange = event => {
        this.setState({ storeId: event.target.value });
    }

    handlePassCodeValidToChange = (event) => {
        this.setState({ passCodeValidTo: event.target.value });
    };

    handleRoleChange = event => {
        this.setState({ roleId: event.target.value });
    }
    handleRoleValidFromChange = (event) => {
        this.setState({ roleValidFrom: event.target.value });
    };

    handleRoleValidToChange = (event) => {
        this.setState({ roleValidTo: event.target.value });
    };

    backBtnClick = (event) => {
        event.preventDefault();
        //window.location.href = '/users';
        this.props.saveCallback("");
    };
    
    revalidateUserFromAD = (event) => {
        this.setState({isADValidationSuccess: false});
        this.setState({isDisclaimerChecked: false});
        this.setState({isDisclaimerOn: false});
        this.setState({userId: ""});
        this.setState({firstName: ""});
        this.setState({lastName: ""});
        this.setState({email: ""});
        this.setState({storeId: ""});
        this.setState({roleId: ""});
    }

    validateUserFromAD = (event) => {
        event.preventDefault();
        var userANumber = this.state.userId;
        var userAEmail = this.state.email;
        if(userAEmail!=null && userAEmail.length<1)
        {
            userAEmail = "NA";
        }
        var API= getHttpCommon(this.state.accessToken).get('api/User/validateADUser2/' + userANumber + '/' + userAEmail)
        .then(res => {
            console.log("Validate AD success:"+ res);
            console.log(JSON.stringify(res.data));

            if(res.data.UserId == null && res.data.firstName ==null && res.data.lastName ==null)
            {
                alert("The userId is not exists in H&M AD")
                this.setState({isDisclaimerOn: true})
                this.setState({isADValidationSuccess: false})
            }
            else
            {
                //this.setState({userId: res.data.userId})
                this.setState({firstName: res.data.firstName})
                this.setState({lastName: res.data.lastName})
                this.setState({email: res.data.email})
                this.setState({isDisclaimerOn: false})
                this.setState({isADValidationSuccess: true})
            }
            /*this.setState({
                roles: res.data
            });*/

        }).catch((error)=>{
            console.log("Validate AD failed:"+ error);
            var response = error.response;
            var errorMessage = response.data.detail;
            alert(errorMessage);
            //handleADValidationError();
        });
        API.interceptors.response.use(function (response) {
            if (response.status === 400) {
                // Do something here with Hooks or something else
                return response;
            }
            handleADValidationError("true");
            return response;
        });
        console.log("API:" + API)
    };

    handleADValidationError(){
        
    }

    handleDisclaimerCheckbox = event => {
        this.setState({ isDisclaimerChecked: event.target.checked });
    }

    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ validated: true });

        }
        else {
            event.preventDefault();

            const userDetails = {
                userId: this.state.userId,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                passCode: this.state.passCode,
                passCodeValidTo: new Date(this.state.passCodeValidTo).toISOString(),
                mappedToStore: this.state.storeId,
                roleId: this.state.roleId,
                roleValidFrom: new Date(this.state.roleValidFrom).toISOString(),
                roleValidTo: new Date(this.state.roleValidTo).toISOString(),
                IsActive: "true"
            };
          
            getHttpCommon(this.state.accessToken, this.loginUserEmail).post('api/user?isValidated=' + this.state.isADValidationSuccess, userDetails)
                .then(res => {
                    alert(res.data);
                    window.location.href = '/users';
                    //TODO: Refresh the user by callback
                    //console.log(userDetails)
                    this.props.savCallback("success");
                })
                .catch(function (error) {
                    console.log(error);
                    var response = error.response;
                    var errorMessage = response.data.detail;
                    alert(errorMessage);
                });
        }
    }

    render() {
        return (
            <div id="container" >
                <div>
                    <div>
                        <div style={{ marginTop: '20px', width: '100%'}}>
                            <h4>Add New User</h4>
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                              <table style={{ marginTop: '20px', width: '100%'}} className="formInnerStyle">
                                <tr >
                                    <td>
                                    <Form.Group>
                                            <Form.Label className="formFieldSpace">User Id</Form.Label>
                                            <table >
                                                <tr>
                                                    <td style={{ width:'50%'}}>
                                                        <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="User Id"
                                                        value={this.state.userId}
                                                        disabled={(this.state.isADValidationSuccess)}
                                                        onChange={this.handleUserIdChange}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide User Id
                                                        </Form.Control.Feedback>
                                                    </td>
                                                    <td style={{ verticalAlign: "top", width:"15%"}}>
                                                        <Button type="button" onClick={this.validateUserFromAD} style={{ marginTop: '0px', marginLeft: '0px' }}
                                                        disabled={!this.state.userId}>Find User</Button>
                                                    </td>
                                                    <td style={{ verticalAlign: "top", width:"15%" }}>
                                                        <Button variant="secondary" type="button" onClick={this.revalidateUserFromAD} style={{ marginTop: '0px', marginLeft: '10px' }}>Reset</Button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </Form.Group>
                                        {this.state.isDisclaimerOn && <Form.Group className="mb-3" style={{ marginTop: '12px', marginLeft: '0px', color:'red'}}>
                                            <Form.Check type="checkbox" label="I Accept/Understood.  It is Highly Risk to add a non AD user and generate passcode. [** Please provide a valid H&M provided email address to onboard**]"
                                            checked={this.state.isDisclaimerChecked}
                                                onChange={this.handleDisclaimerCheckbox} />
                                        </Form.Group>}
                                    </td>
                                    <td>
                                    <Form.Group>
                                        <Form.Label className="formFieldSpace">Store</Form.Label>
                                        <Form.Control as="select" placeholder="Store" 
                                        required onChange={this.handleStoreChange} 
                                        value={this.state.storeId}>
                                            <option value=''>--Select a Store--</option>
                                            {this.state.stores.map((item) => (
                                                <option className='notranslate' value={item.storeId}>{item.storeName}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Store
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <Form.Group>
                                        <Form.Label className="formFieldSpace">Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            required={this.state.isDisclaimerOn}
                                            placeholder="Email"
                                            value={this.state.email}
                                            onChange={this.handleEmailChange}
                                            className='notranslate'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                                Please provide Email
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group>
                                        <Form.Label className="formFieldSpace">Role</Form.Label>
                                        <Form.Control as="select" placeholder="Role" 
                                        required onChange={this.handleRoleChange} 
                                        value={this.state.roleId}>
                                            <option value=''>--Select a Role--</option>
                                            {this.state.roles.map((item) => (
                                                <option className='notranslate' value={item.roleId}>{item.description}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Role
                                        </Form.Control.Feedback>
                                    </Form.Group>        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <Form.Group>
                                        <Form.Label className="formFieldSpace">First name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="First name"
                                            value={this.state.firstName}
                                            onChange={this.handleFirstNameChange}
                                            className='notranslate'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide First name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group>
                                        <Form.Label className="formFieldSpace">Role Valid From</Form.Label>
                                        <Form.Control type="datetime-local" placeholder="RoleValidFrom" required onChange={this.handleRoleValidFromChange} 
                                        value={this.state.roleValidFrom}/>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Role Valid From
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <Form.Group>
                                        <Form.Label className="formFieldSpace">Last name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Last name"
                                            value={this.state.lastName}
                                            onChange={this.handleLastNameChange}
                                            className='notranslate'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Last name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group>
                                        <Form.Label className="formFieldSpace">Role Valid To (90 days)</Form.Label>
                                        <Form.Control type="datetime-local" placeholder="RoleValidTo" required onChange={this.handleRoleValidToChange} 
                                            value={this.state.roleValidTo}/>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Role Valid To
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <Form.Group>
                                        <Form.Label className="formFieldSpace">Passcode Valid To (90 days)</Form.Label>
                                        <Form.Control type="datetime-local" placeholder="Passcode" required onChange={this.handlePassCodeValidToChange} 
                                        value={this.state.passCodeValidTo}/>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Passcode Valid To
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </td>
                                    <td>
                                    
                                    </td>
                                </tr>
                            </table>
                            <div style={{ marginTop: '30px',marginBottom: '20px', textAlign:"center"}}>
                                <button className="btnNormalBkp"  type="submit" style={{ marginTop: '10px' }} 
                                    disabled={(this.state.isDisclaimerOn && !this.state.isDisclaimerChecked) || (!this.state.isDisclaimerOn && !this.state.isADValidationSuccess)}>Add User</button>
                                   
                                <span className="btnNormal"  type="submit" onClick={this.backBtnClick} style={{ marginTop: '10px', marginLeft: '10px' }}>Back</span>  
                            </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}